<template>
<div class="App">
      <div class="vertical-center">
        <div class="inner-block">
           <div class="vue-tempalte">
                <h3>  <img src = 'https://mandirikanwil1.com/mandiriok.png'> <br>Dashboard Region I
              </h3>

                <div class="form-group">
                    <label>Username / URC</label>
                    <input type="text" class="form-control form-control-lg" v-model="formlogin.username" />
                </div>
                <div class="form-group">
                    <label>Password</label>
                    <input type="password" class="form-control form-control-lg" v-model="formlogin.password" />
                </div>
                <button class="btn btn-warning btn-lg btn-block" @click="signIn">Sign In</button>
<small>&copy; Copyright CFO Region I</small>
          
               </div>
        </div>
      </div>
    </div>
   
</template>

<script>
    import axios from 'axios';
    export default {
        components: {},
        data() {
            return {
                formlogin : {
                    username : '',
                    password : ''    
                }
            }
        },
        methods: {
            signIn(){
                    let vm = this;
                    // this.dataEmail = {email : this.$parent.$data.infogoogle.email, google_id : this.$parent.$data.infogoogle.googleId};
                    axios.post(this.$root.baseUrlApi+'functions/auth/login.php', this.formlogin,
                        {
                            headers : {},
                        }).then(function (response) {
                            vm.$parent.$data.authenticated = true;
                            vm.$parent.$data.accessToken = response.data.access_token;
                            localStorage.setItem('accessTokenDashboard', response.data.access_token);
                            localStorage.setItem('authenticatedDashboard', true);
                            vm.$forceUpdate();
                            // location.reload();
                            console.log(response);
                        }).catch(function (error) {
                            console.log(error);
                            if (!error.response) {
                                alert("Please check your connection and try again");
                            } else {
                                if (error.response.data.message) {
                                    alert(error.response.data.message);
                                } else {
                                    alert("error occured in server");
                                }
                            }
                    });
                }
        }
    }
</script>