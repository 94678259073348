<template>
    <Login v-if="!authenticated"></Login>
    <section v-else>
        <router-view />
    </section>
</template>

<script>
import Login from './components/Login.vue'
// import HomeComponent from './components/HomeComponent.vue'
    export default {
        name: 'App',
        components : {Login},
        data() {
            return {
               authenticated : false,
               accessToken : ''
            }
        },
        created() {
            if (localStorage.getItem('accessTokenDashboard')) {
                this.accessToken = localStorage.getItem('accessTokenDashboard');
            }
            
            if (localStorage.getItem('authenticatedDashboard')) {
                this.authenticated = true;
            }
        }
    }
</script>
