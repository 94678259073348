import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../components/HomeComponent.vue')
    },
    {
      path: '/changepassword',
      name: 'changepassword',
      component: () => import('../components/ChangePassword.vue')
    },
    {
      path: '/credit',
      name: 'credit',
      component: () => import('../components/Credit.vue')
    },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router